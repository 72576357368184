<template>
  <div v-if="cancelConfirmed" class="cancel-confirmed">
    <h1 class="cancellation-title">Compliance Cancellation</h1>
    <div class="cancel-message">
      <p>
        We're sad to hear that you're canceling your Compliance Service for {{ companyName }},
        {{ jurisdiction }}. Your cancellation request has been processed, but we hope you'll
        consider us again in the near future for all your Compliance needs.
      </p>
      <p v-if="canRefund">
        You will receive an email when you have been refunded ${{ calculateRate.toFixed(2) }} to your original payment method.
      </p>
      <p>
        To allow us to file your state filings in the future, you will need to reactivate the Compliance Service.
      </p>
    </div>
  </div>
  <div v-else-if="loaded && canCancel" class="cancel-service">
    <div class="header">
      <p class="cancel-service-title">Cancel Service</p>
      <p class="company-name">{{ companyName }}</p>
    </div>

    <div class="service-card">
      <b-row class="header-row">
        <b-col>
          Compliance Service and State Filing
        </b-col>
      </b-row>
      <b-row class="body-row">
        <b-col>
          Jurisdiction
        </b-col>
        <b-col class="text-right">
          {{ jurisdiction }}
        </b-col>
      </b-row>
      <b-row class="body-row">
        <b-col>
          Rate
        </b-col>
        <b-col class="text-right">
          ${{ calculateRate.toFixed(2) }}
        </b-col>
      </b-row>
      <b-row :class="['body-row', { 'no-border': selectedReason === 'other' }]">
        <b-col>
          Reason for Cancellation<span class="text-danger">*</span>
        </b-col>
        <b-col class="text-right">
            <select v-model="selectedReason" class="form-control select-box" aria-label="Reason for cancellation">
              <option disabled value="">Please select a reason</option>
              <option v-for="reason in reasons" :key="reason" :value="reason">{{ reason }}</option>
            </select>
        </b-col>
      </b-row>
      <b-row v-if="selectedReason === 'Other'" class="body-row">
        <b-col>
          <textarea
            v-model="otherNote"
            class="form-control"
            placeholder="Enter reason"
            maxlength="250"
            aria-label="Enter reason for cancellation"
          ></textarea>
          <div class="text-right text-muted">{{ remainingCharacters }} characters left</div>
        </b-col>
      </b-row>
    </div>

    <div class="refund-card" v-if="canRefund">
      <b-row class="header-row">
        <b-col>
          Refund Details
        </b-col>
        <b-col class="text-right">
          ${{ invoice.total.toFixed(2) }}
        </b-col>
      </b-row>
      <b-row class="body-row">
        <b-col>
          Invoice #
        </b-col>
        <b-col class="text-right">
          {{ invoice.invoice_number }}
        </b-col>
      </b-row>
      <b-row class="body-row">
        <b-col>
          Payment Method
        </b-col>
        <b-col class="text-right">
          <b-img :src="cardLogo(invoice.payment_info.card.brand)" class="mr-2" />
          **{{ invoice.payment_info.card.last4 }}
        </b-col>
      </b-row>
    </div>

    <div class="action-buttons">
      <b-row>
        <b-col class="text-left">
          <b-button
            class="grey-button-outlined"
            variant="outline-secondary"
            aria-label="Cancel and go back"
            :to="'/dashpanel'">
            Cancel
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            class="custom-blue-button"
            aria-label="Submit cancellation"
            :disabled="!selectedReason || (selectedReason === 'Other' && otherNote.length === 0)"
            @click="openCancellationConfirmation"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </div>

    <cancellation-confirmation-modal
      v-model="showModal"
      :company-name="companyName"
      :jurisdiction="jurisdiction"
      :reason="selectedReason"
      :refund-amount="canRefund ? invoice.total.toFixed(2) : null"
      :card="canRefund ? invoice.payment_info.card : null"
      @confirm="confirmCancellation"
    />
  </div>

  <div v-else-if="loaded" class="cancel-confirmed">
    <span>No filings or active compliance service available for refund / cancel</span>
  </div>

  <div v-else class="nav-spinner-container">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getCardLogo } from '@/common/modules/cc'
import { makeToastMixin } from '@/mixins/makeToastMixin'

const REFUND_STATUSES = [
  'pending',
  'awaiting-client-input',
  'renewal-filed-by-client',
  'not-filed-in-state',
  'new',
]

export default {
  name: 'CancelService',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    CancellationConfirmationModal: () => import('@/components/CancellationConfirmationModal.vue'),
  },
  mixins: [makeToastMixin],
  data() {
    return {
      activeFiling: null,
      loaded: false,
      selectedReason: '',
      reasons: [
        "No Longer Need Compliance Service",
        "Filing Compliance Myself",
        "Other",
      ],
      otherNote: '',
      cancelConfirmed: false,
      complianceService: null,
      companyName: '',
      showModal: false,
      invoice: null,
    }
  },
  computed: {
    ...mapGetters('account', ['activeAccountServices', 'actualAccountID']),
    ...mapGetters('companies', ['currentCompany']),
    ...mapGetters('orderItems', ['complianceFilings']),
    ...mapGetters('services', ['service', 'services']),
    calculateRate() {
      if (this.activeFiling) {
        return this.activeFiling.product.price + this.activeFiling.product.filing_method.cost
      }
      return 0
    },
    canRefund() {
      return this.invoice?.status === 'paid' &&
        this.invoice?.payment_info?.card &&
        REFUND_STATUSES.includes(this.activeFiling?.status)
    },
    canCancel() {
      return this.activeFiling || this.complianceService?.status === 'active'
    },
    jurisdiction() {
      return this.complianceService.jurisdiction?.state_province_region
    },
    remainingCharacters () {
      return 250 - this.otherNote.length
    },
  },
  async mounted() {
    await this.getServices({ include_jurisdiction: true })
    this.complianceService = this.service(this.$route.params.id)
    if (this.complianceService) {
      await this.setCurrentCompany({ id: this.complianceService?.company_id })
      await this.loadComplianceActiveFilings(this.complianceService.id)
      this.companyName = this.currentCompany?.name
      this.activeFiling = this.complianceFilings.find(svc => svc.registration_id === this.complianceService.object_id)
      if (this.activeFiling) {
        this.invoice = await this.fetchInvoiceFromOrderItem(this.activeFiling.id)
      }
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('orderItems', ['loadComplianceActiveFilings']),
    ...mapActions('companies', ['setCurrentCompany']),
    ...mapActions('invoices', ['clientRefundRequest', 'fetchInvoiceFromOrderItem']),
    ...mapActions('services', ['getServices', 'cancelServiceAndFilings']),
    openCancellationConfirmation() {
      this.showModal = true
    },
    async confirmCancellation() {
      this.loaded = false
      if (this.canRefund) {
        const params = {
          account_id: this.actualAccountID,
          invoice_id: this.invoice.id,
          refund_reason: this.selectedReason === 'other' ? this.selectedReason + " - " + this.otherNote : this.selectedReason,
        }
        const response = await this.clientRefundRequest(params)

        if (!response.success) {
          this.errorToast('Error', 'Error creating refund, please try again or contact customer service')
          this.loaded = true
          return
        }
      }
      const cancellation_reason = this.otherNote ? this.otherNote : this.selectedReason
      const cancel_params = {
        cancel: {
          service_ids: [this.complianceService.id],
          order_item_ids: this.activeFiling ? [this.activeFiling.id] : [],
          signature: "",
          note: null,
          cancellation_reason_detail: cancellation_reason,
          selected_cancellation_reason_id: null,
        },
      }
      const data = await this.cancelServiceAndFilings(cancel_params)

      if (data.success === true) {
        this.cancelConfirmed = true
      }
      else {
        this.errorToast('Error', 'Error canceling service / filing, please try again or contact customer service')
      }
      this.loaded = true
    },
    cardLogo(brand) {
      return getCardLogo(brand);
    },
  },
}
</script>

<style scoped>
.nav-spinner-container {
  margin-top: 33vh;
}

.cancel-confirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  max-width: 711px;
  margin: 0 auto;
}

.cancellation-title {
  width: 100%;
  text-align: left;
  margin-top: 0;
  margin-bottom: 2rem;
}

.cancel-message {
  width: 100%;
  text-align: left;
}

.cancel-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;


  .header {
    width: 100%;
    max-width: 711px;
    margin-top: 0;
    text-align: left;

    .cancel-service-title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    .company-name {
      margin-top: 0;
      font-size: 1.5rem;
    }
  }

  .service-card, .refund-card {
    width: 100%;
    max-width: 711px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .header-row {
    font-weight: bold;
    font-size: 1.25rem;
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 1rem;
    margin: auto;
    align-items: center;
  }

  .body-row {
    border-bottom: 1px solid #ddd;
    width: 95%;
    padding: 1rem 0;
    margin: auto;
    align-items: center;
  }

  .body-row:last-child {
    border-bottom: none;
  }

  .no-border {
    border-bottom: none;
  }

  .text-right {
    text-align: right;
  }

  .text-right.text-muted {
    margin-left: auto;
  }

  .form-control {
    width: 100%;
  }

  .select-box {
    min-height: unset !important;
    min-width: 350px;
    height: 45px;
  }

  .select-box:hover {
    cursor: pointer;
  }

  .action-buttons {
    margin: 2rem;
    width: 100%;
    max-width: 711px;
  }

  .grey-button-outlined {
    background-color: #FFFFFF !important;
    border-color: #CECED2 !important;
    border-radius: 5px;
    color: #4E4E52;
  }
  .grey-button-outlined:hover {
    background-color: #CDCDCD !important;
  }

  .custom-blue-button {
    background-color: #2E3798 !important;
    border-radius: 5px;
    color: white !important;
  }

  .custom-blue-button[disabled] {
    background-color: #e8e4e4 !important;
    border-color: transparent !important;
    border-radius: 5px;
    color: #939292 !important;
    cursor: not-allowed !important;
  }

  .custom-blue-button:hover:not([disabled]) {
    background-color: #1e2b78 !important;
  }

  @media (max-width: 768px) {
    .select-box {
      min-width: 50px;
    }
  }
}
</style>
